/*--------------------------
    Color Darker Version  
----------------------------*/

.active-dark {
    background: #F5F5F5;
    [data-overlay="6"]:before,
    [data-black-overlay="6"]:before,
    [data-white-overlay="6"]:before {
        display: none;
    }
    [data-overlay]:before,
    [data-black-overlay]:before,
    [data-white-overlay]:before {
        display: none;
    }
    .service-white .service__style--1 .content h4.title{
        color: #ffffff;
    }

    &.bg_color--9 {
        .creative-service-wrapper .row.creative-service ,
        .header-area.header--fixed.sticky,
        .backto-top>div,
        .bg_color--6 {
            background: #091020;
        }
    }

    &.bg_color--9 {
        .service.service__style--2  {
            background: #ffffff;
        }
    }


    .im_counterup {
        background: #0c0c0c;
    }

    .service-main-wrapper, .service.service__style--2  {
        background: #ffffff;
    }

    
    .bg_image--3,
    .bg_image--1 ,
    .rn-testimonial-content .inner p::before,
    .rn-testimonial-content .inner p::after ,
    .footer-right  {
        background-image: inherit !important;
    }
    .bg_color--1 ,
    .brand-separation {
        background: #191919;
    }

    .bg_color--5,
    .bg_color--6 {
        background: #262428;
    }
   
    .bg_color--ugs {
        background: #F5F5F5;
    }

    .card_color--ugs {
        background: #ffffff;
    }
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        color: #ffffff;
    }
    a.btn-transparent {
        color: #ffffff; 
    }
    .section-title p ,
    .about-us-list p ,
    .about-inner .section-title p.description,
    .service.service__style--2 .content p ,
    .counterup_style--1 p.description ,
    .rn-testimonial-content .author-info h6 ,
    .slide.slider-style-3 .inner>span ,
    .single-tab-content ul li a ,
    .single-tab-content ul li {
       color: rgba(198, 201, 216, 0.75)
    }

    .service.service__style--2 {
       color: #ffffff
    }

    ul.tab-style--1 {
        border-color: #2d3548;;
    }
    ul.tab-style--1 li::before {
        background: rgba(198, 201, 216, 0.75);
    }
    ul.tab-style--1 li.react-tabs__tab--selected {
        color: var(--color-white);
    }

    .service.service__style--2:hover .content h3.title,
    .rn-testimonial-content .inner p ,
    .section-title p a ,
    .slide.slider-style-3 .inner h1.title ,
    .color-black .mainmenunav ul.mainmenu>li>a ,
    ul.social-share.social-style--2.color-black li a ,
    .header-area.color-black a.rn-btn ,
    ul.tab-style--1 li {
        color: #ffffff; 
    }
    .portfolio-sacousel-inner .slick-dots li button::after,
    .rn-slick-dot .slick-dots li button::after {
        background: #ffffff;
        box-shadow: inset 0 0 0 5px #ffffff;
    }

    .creative-service-wrapper .row.creative-service {
        background: #191919;
    }

    .service.service__style--2 {
        background: #ffffff;
    }

    .contact-form--1 input, .contact-form--1 textarea ,
    .header-area.color-black a.rn-btn {
        border: 2px solid rgba(255,255,255,0.2);
        background:#F3F3F8;
    }
    .header-area.color-black a.rn-btn:hover{
        border-color: var(--color-primary);
    }
    .header-area.header--fixed.sticky {
        background: #191919;
    }
    .header-area.header--fixed.sticky .header-wrapper a.rn-btn {
        color: #ffffff;
        border-color: #ffffff;
    }
    .header-area.header--fixed.sticky .header-wrapper a.rn-btn:hover{
        color: #ffffff;
        border-color: var(--color-primary);
    }
    .bg_image--25 {
        background-color: #101010;
        background-image: url(/assets/images/bg/bg-image-28.jpg) !important;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .bg_image--ugs-mockup {
        background-color: #101010;
        background-image: url(/assets/images/ugs-mockup.png) !important;
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 70% auto;
        @media #{$large-mobile} {
            background-position: center right;
        }
    }

    .bg_image--ugs-mockup3 {
        background-color: #101010;
        background-image: url(/assets/images/ugs-mockup3.png) !important;
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 50% auto;
        @media #{$large-mobile} {
            background-position: center right;
        }
    }

    .backto-top>div {
        background-color: #0c0c0c;
        margin-bottom: 40px;
    }
    .backto-top svg {
        color: #fff;
    }
    .contact-form--1 input,
    .contact-form--1 textarea {
        color: rgba(0, 0, 0, 1)
    }

    .personal-portfolio-slider::before {
        display: none;
    }

    ul.testimonial-thumb-wrapper li .thumb::after {
        background-color: rgba(0,0,0,0.5);
    }

    ul.testimonial-thumb-wrapper li.react-tabs__tab--selected .thumb::after {
        background-color: rgba(0,0,0,0);
    }

    .header-area.color-black a.btn-default.btn-border.btn-opacity {
        color: var(--color-white);
        border-color: var(--color-primary);
    }
    
    .header-area.color-black a.btn-default.btn-border.btn-opacity:hover {
        color: rgba(198,201,216,0.75);
        border-color: rgba(198,201,216,0.75);
    }
    .im_address .link {
        color: rgba(198,201,216,0.75);
    }
    .im_address .link:hover {
        color: var(--color-primary);
    }
    .rn-progress-bar .single-progress .title {
        color: rgba(198,201,216,0.75);
    }

    .rn-progress-bar .single-progress .label {
        color: rgba(198,201,216,0.75);
    }

    .im_portfolio:hover {
        box-shadow: 0 25px 55px rgba(20, 23, 29,0.05);
    }

    @media #{$md-layout} {
        .header-style-two .humberger-menu span.text-white {
            color: #c6c9d8 !important;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu {
            background: #101010;
        }
        .header-area .header-wrapper.menu-open .close-menu span {
            color: #c6c9d8 !important;
        }
        .header-area.header--fixed.sticky .humberger-menu span.text-white {
            color: #c6c9d8 !important;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li a  {
            color: #ffffff;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown>a::after {
            border-color: #ffffff transparent transparent transparent;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown>a.open::after {
            border-color: transparent transparent var(--color-primary) transparent
        }
    }
    @media #{$sm-layout} {
        .header-style-two .humberger-menu span.text-white {
            color: #c6c9d8 !important;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu {
            background: #101010;
        }
        .header-area .header-wrapper.menu-open .close-menu span {
            color: #c6c9d8 !important;
        }
        .header-area.header--fixed.sticky .humberger-menu span.text-white {
            color: #c6c9d8 !important;
        }
        .service.service__style--2 .content p {
            color: #ffffff;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li a  {
            color: #ffffff;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown>a::after {
            border-color: #ffffff transparent transparent transparent;
        }
        .header-area .header-wrapper .mainmenunav ul.mainmenu li.has-droupdown>a.open::after {
            border-color: transparent transparent var(--color-primary) transparent
        }
    }

    .pv-tab-button {
        border-color: #403c3c;
    }
    .accodion-style--1 .accordion__item .accordion__heading .accordion__button {
        color: #fff;
    }
    .accodion-style--1 .accordion__item .accordion__heading .accordion__button::after {
        background: #353434;
    }

    .accodion-style--1 .accordion__item .accordion__panel p {
        color: rgba(198,201,216,0.75);
    }

    div.top-inset {
        display: inline-block;
        position:relative;
        width: 90%;
        left: 5%;
        top:-130px;
        border: 1px solid #efefef;
        background-color: #ffffff;
    }

    div.top-inset-main {
        display: inline-block;
        position:relative;
        width: 100%;
        top:-80px;
    }

    div.bottom-inset {
        display: inline-block;
        position:relative;
        width: 90%;
        left: 5%;
        top:-30px;
        border: 1px solid #efefef;
        background-color: #ffffff;
        margin-bottom: -90px;
    }

    .ugs-card {
        display: inline-block;
        position:relative;
        border: 1px solid #efefef;
        background-color: #ffffff;
    }

   
    .ugs-card-bottom {
        width: 100%;
        display: inline-block;
        position:relative;
        bottom:100px;
        border: 1px solid #efefef;
        background-color: #f5f5f5;
    }

    p.header-title { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: 600; 
        font-size: 48px; 
        line-height: 72px; 
        letter-spacing: 0.02em; 
        color: #FFFFFF; 
    } 
 
    p.header-sub-title { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: 600; 
        font-size: 36px; 
        line-height: 63px; 
        letter-spacing: 0.02em; 
        color: #90949C; 
    } 

    p.header-sub-title-light { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: 600; 
        font-size: 36px; 
        line-height: 63px; 
        letter-spacing: 0.02em; 
        color: #c0c4cC; 
    } 
 
    p.section-title { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: 600; 
        font-size: 24px; 
        line-height: 36px; 
        text-align: center; 
        letter-spacing: 0.02em; 
        color: #060408; 
    } 
 
    p.section-description { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: normal; 
        font-size: 18px; 
        line-height: 27px; 
        text-align: center; 
        color: #404040; 
    } 
 
    p.header-sub-title { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: 500; 
        font-size: 36px; 
        line-height: 63px; 
        letter-spacing: 0.02em; 
        color: #404040; 
    } 
     
    p.item-title-medium { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: 600; 
        font-size: 16px; 
        line-height: 175%; 
        text-align: center; 
        letter-spacing: 0.02em; 
        color: #404040; 
    } 
 
    p.move-top { 
        margin-top: -30px;
    }

    p.item-description-medium { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: normal; 
        font-size: 16px; 
        line-height: 24px; 
        text-align: center; 
        color: #404040; 
    } 
 
    p.item-title-large { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: 600; 
        font-size: 20px; 
        line-height: 32px; 
        color: #060408; 
    } 
 
    p.item-description-large { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: normal; 
        font-size: 16px; 
        line-height: 160%; 
        color: #404040; 
    } 
 
    p.footer-title-large { 
        font-family: Poppins; 
        font-style: bold; 
        font-weight: 600; 
        font-size: 30px; 
        line-height: 45px; 
        letter-spacing: 0.02em; 
        color: #FFFFFF; 
    } 
 
    p.footer-title { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: 300; 
        font-size: 30px; 
        line-height: 45px; 
        letter-spacing: 0.02em; 
        color: #FFFFFF; 
    } 
    p.footer-sub-title { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: 400; 
        font-size: 20px; 
        line-height: 36px; 
        letter-spacing: 0.02em; 
        color: #404040; 
        margin-top: 50px;
    }

    img.logo {
        width: 60px;
        height: 60px;
    }

    img.logo-text {
        width: auto;
        height: 28px;
        position:relative;
        left:0px;
    }

    img.logo-text-mobile {
        width: auto;
        height: 20px;
        position:relative;
        left:0px;
    }

    img.service-logo {
        width: 50px;
        height: 50px;
        margin-right: 30px;
        margin-left: 10px;
        // align-self: center;
    }

    .center {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
      }

      .center-horizontal {
        margin: 0;
        position: absolute;
        left: 50%;
        transform: translate(50%, 0%);
      }

    .number-border {
        width: 70px;
        height: 70px;
        border-radius: 10px;
        border: 1px solid #CCCCCC;
        justify-content: center;
    }

    .contact-style { 
        font-family: Poppins; 
        font-style: normal; 
        font-weight: normal; 
        font-size: 16px; 
        line-height: 24px; 
        color: #404040; 
    } 
}
